import XLSX from "xlsx";

export function excelToArray(file, callback) {
  const reader = new FileReader();

  reader.onload = function(e) {
    let data = e.target.result;

    data = new Uint8Array(data);

    const workbook = XLSX.read(data, {
      type: "array"
    });

    const first_sheet_name = workbook.SheetNames[0];

    const worksheet = workbook.Sheets[first_sheet_name];

    const json = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      raw: false
    });

    json?.splice(0, 1);

    callback(json);
  };

  reader.readAsArrayBuffer(file);
}
