<template>
  <div data-app>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-select
              id="input-lg"
              value-field="nickname"
              text-field="nickname"
              size="lg"
              v-model="selectNickname"
              @change="selectProject"
            >
              <b-form-select-option
                v-for="(proj, i) in this.projectList"
                v-bind:key="i"
                :value="{ code: proj.code, text: proj.nickname }"
              >
                {{ proj.nickname }}
              </b-form-select-option>
            </b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Owner</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.ownerName"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.projectName"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Contract Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.contractNumber"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="selectedNickname.status"
              disabled
            ></b-form-input>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end pb-3">
        <div>
          <b-button
            v-if="currentEditId"
            class="ml-auto mr-2"
            variant="success"
            @click="save"
          >
            <i class="flaticon2-check-mark"></i> Save
          </b-button>

          <b-button class="ml-auto" variant="success" @click="loadData">
            Load Design
          </b-button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row mb-5">
          <div class="col-md-4 py-2 col-sm-12">
            <input
              type="file"
              class="d-none"
              ref="uploadExcel"
              @change="handleUploadExcel"
            />

            <b-dropdown right variant="success" class="ml-2">
              <template #button-content>
                <i class="fa fa-file-excel" /> Excel
              </template>

              <b-dropdown-item>
                <a class="d-inline text-dark" @click="uploadExcel">
                  <i class="flaticon-upload-1 mr-2"></i> Upload
                </a>
              </b-dropdown-item>

              <vue-excel-xlsx
                :data="excelData"
                :columns="exportExcelColumn"
                :file-name="fileName"
                :sheetname="'sheet1'"
              >
                <b-dropdown-item>
                  <div class="d-inline">
                    <i class="flaticon-download mr-2"></i> Download
                  </div>
                </b-dropdown-item>
              </vue-excel-xlsx>
            </b-dropdown>
          </div>

          <div class="col-md-4 offset-md-4 py-2 col-sm-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-search-1"></i>
                </span>
              </div>

              <input
                type="text"
                v-model="searchData"
                @change="this.$forceUpdate()"
                class="form-control"
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <complete-table :loading="loading" :data="dataWithFilter">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 150px">Action</th>
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Numbering</span>
              </th>
              <th style="min-width: 200px">External Numbering</th>
              <th style="min-width: 250px">Asbuilt Drawing Numbering</th>
              <th style="min-width: 250px">Title</th>
              <th style="min-width: 100px">Station</th>
              <th style="min-width: 100px">Type</th>
              <th style="min-width: 100px">Scope</th>
              <th style="min-width: 150px">Status</th>
              <th style="min-width: 100px">Revision</th>
              <th style="min-width: 150px">Version</th>
              <th style="min-width: 150px">Plan Date</th>
              <th style="min-width: 150px">PIC Drafter</th>
              <th style="min-width: 150px">Outstanding</th>
              <th style="min-width: 150px">Completed</th>
              <th style="min-width: 250px">Storage</th>
              <th style="min-width: 200px">Transmittal Date</th>
              <th style="min-width: 200px">Delivery Date</th>
              <th style="min-width: 200px">Receiver</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pr-0">
                <div class="d-flex">
                  <a
                    class="btn btn-secondary font-weight-bolder font-size-sm mr-2"
                    @click="openHistoryModal(item)"
                  >
                    <i class="flaticon-list-2"></i>
                  </a>

                  <on-edit-row :is-edit="isEdit(item.id)">
                    <template #onView>
                      <a
                        class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                        @click="editData(item)"
                      >
                        <i class="flaticon-edit"></i>
                      </a>
                    </template>

                    <template #onEdit>
                      <a
                        class="btn btn-light-danger font-weight-bolder font-size-sm mr-2"
                        @click="cancelEdit"
                      >
                        <i class="flaticon2-cross"></i>
                      </a>
                    </template>
                  </on-edit-row>
                </div>
              </td>

              <td class="pl-0">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.numbering }}
                </span>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.external_numbering }}
                    </span>
                  </template>

                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.external_numbering"
                    />
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.asbuilt_drawing_numbering"
                    />
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.asbuilt_drawing_numbering }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.station ? item.station.name : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.type ? item.type.name : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.scope ? item.scope.name : "-" }}
                </span>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-form-select
                      v-model="currentEditForm.status"
                      :options="formattedStatusOptions"
                      size="lg"
                    ></b-form-select>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.last_status ? item.last_status.name : "-" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.revision_version"
                    />
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.revision_version }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <input
                      class="form-control"
                      v-model="currentEditForm.version"
                    />
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.version }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-input-group>
                      <input
                        class="form-control"
                        v-model="currentEditForm.planning_date"
                        type="text"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          v-model="currentEditForm.planning_date"
                          :no-flip="true"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{
                        moment(item.planning_date).isValid() ? 
                        moment(item.planning_date).format("DD-MM-YYYY") : "" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-form-select
                      v-model="currentEditForm.drafter_pic"
                      :options="formattedPicOptions"
                      size="lg"
                    ></b-form-select>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.drafter_pic ? item.drafter_pic.name : "-" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-form-select
                      v-model="currentEditForm.outstanding"
                      :options="formattedOutstandingOptions"
                      size="lg"
                    ></b-form-select>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.outstanding ? item.outstanding.name : "-" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="currentEditForm.date_completed"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          v-model="currentEditForm.date_completed"
                          :no-flip="true"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{
                        moment(item.date_completed).isValid() ? 
                        moment(item.date_completed).format("DD-MM-YYYY") : "" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <button
                      class="btn btn-primary btn-md"
                      @click="showSelectServer = true"
                    >
                      Browse
                    </button>
                  </template>

                  <template #onView>
                    <a
                      class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                      @click="
                        downloadFile(item.asbuilt_file, item.asbuilt_file)
                      "
                      >{{ item.asbuilt_file_name }}</a
                    >
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="currentEditForm.transmittal_date"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          :no-flip="true"
                          v-model="currentEditForm.transmittal_date"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ 
                        moment(item.transmittal_date).isValid() ?
                        moment(item.transmittal_date).format("DD-MM-YYYY") : "" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="currentEditForm.date_receive"
                        id="example-date-input"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          :no-flip="true"
                          v-model="currentEditForm.date_receive"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{
                        moment(item.date_receive).isValid() ? 
                        moment(item.date_receive).format("DD-MM-YYYY") : "" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>

              <td>
                <on-edit-row :is-edit="isEdit(item.id)">
                  <template #onEdit>
                    <b-form-select
                      v-model="currentEditForm.receiver"
                      :options="formattedReceiverOptions"
                      size="lg"
                    ></b-form-select>
                  </template>

                  <template #onView>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.receiver ? item.receiver.name : "-" }}
                    </span>
                  </template>
                </on-edit-row>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <v-dialog v-model="showSelectServer">
      <v-card>
        <v-card-title>
          {{ `Pilih server (${server} - ${selectedServerName})` }}
          <input
            size="lg"
            class="form-control"
            type="text"
            v-model="selectedFullPath"
          />
        </v-card-title>

        <v-card-text v-if="!uploadLoading">
          <v-file-input
            show-size
            :disabled="!selectedServerName"
            label="File input"
            @change="uploadFile"
          ></v-file-input>

          <v-treeview
            :items="servers"
            selection-type="leaf"
            activatable
            item-key="id"
            @update:active="selectServer"
            return-object
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="!item.file">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>

              <v-icon v-else>
                {{ "mdi-folder" }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card-text>

        <v-card-text v-else>
          <div class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="showSelectServer = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="historyModal"
      transition="dialog-top-transition"
      max-width="80vw"
    >
      <v-card>
        <v-card-title class="headline">History</v-card-title>

        <v-card-text>
          <complete-table :loading="historyLoading" :data="histories">
            <template #header>
              <tr class="text-left">
                <th></th>
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Numbering</span>
                </th>
                <th style="min-width: 300px">External Numbering</th>
                <th style="min-width: 300px">Title</th>
                <th style="min-width: 200px">Status</th>
                <th style="min-width: 100px">Revision</th>
                <th style="min-width: 100px">Version</th>
                <th style="min-width: 200px">Date Received</th>
                <th style="min-width: 100px">Delay</th>
                <th style="min-width: 200px">Storage Ruang Doc</th>
                <th style="min-width: 100px">Server</th>
                <th style="min-width: 100px">Asbuilt</th>
                <th style="min-width: 300px">Asbuilt Drawing Numbering</th>
                <th style="min-width: 150px">Return Item</th>
                <th style="min-width: 150px">Outstanding</th>
                <th style="min-width: 150px">Deadline</th>
                <th style="min-width: 150px">PIC Drafter</th>
                <th style="min-width: 150px">Transmittal Number</th>
                <th style="min-width: 150px">Revision SHD</th>
                <th style="min-width: 300px">Plan Date</th>
                <th style="min-width: 150px">Completed Date</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td>
                  <button
                    class="btn btn-default btn-xs"
                    @click="setExpandedIndexDetail(i)"
                  >
                    <span class="flaticon-eye"></span>
                  </button>
                </td>

                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.numbering }}
                  </span>
                </td>

                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.external_numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ optionalObject(item.last_status, "code") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.version }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      moment(item.date_receive).isValid() ?  
                      moment(item.date_receive).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.delay }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.doc_room }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.server }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.monitoring_asbuilt_status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.asbuilt_drawing_numbering }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.return_item }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.outstanding ? item.outstanding.name : null }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ 
                      moment(item.deadline).isValid() ?
                      moment(item.deadline).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.drafter_pic ? item.drafter_pic.name : null }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.transmittal_number }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.revision_shd }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ 
                      moment(item.planning_date).isValid() ? 
                      moment(item.planning_date).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ 
                      moment(item.completed_date).isValid() ?
                      moment(item.completed_date).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>
              </tr>

              <tr
                v-bind:key="i + 'detail-modal'"
                v-if="expandedIndexDetail === i"
              >
                <td colspan="12">
                  <template>
                    <div>
                      <div class="card p-5 gutter-b">
                        <div class="card-body p-0">
                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >External Numbering : </span
                              ><span class="text-dark-75">{{
                                item.external_numbering
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Asbuilt Drawing Numbering : </span
                              ><span class="text-dark-75">{{
                                item.asbuilt_drawing_numbering
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Revision : </span
                              ><span class="text-dark-75">{{
                                item.revision_version
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Version : </span
                              ><span class="text-dark-75">{{
                                item.version
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Plan Date : </span
                              ><span class="text-dark-75">
                                {{
                                  moment(item.planning_date).isValid() ? 
                                  moment(item.planning_date).format(
                                    "DD-MM-YYYY"
                                  ) : ""
                                }}
                              </span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Outstanding : </span
                              ><span class="text-dark-75">{{
                                item.outstanding
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Type : </span
                              ><span class="text-dark-75">{{ item.name }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                              >
                                Station :
                              </span>

                              <span class="text-dark-75">
                                {{ optionalObject(item.station, "name") }}
                              </span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Approved : </span
                              ><span class="text-dark-75">{{
                                item.approved
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Planning Date : </span
                              ><span class="text-dark-75">
                                {{
                                  moment(item.planning_date).isValid() ?
                                  moment(item.planning_date).format(
                                    "DD-MM-YYYY"
                                  ) : ""
                                }}
                              </span>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Category : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.category, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Designer PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.designer_pic, "code")
                                }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Drafter PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.drafter_pic, "code")
                                }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Checker PIC : </span
                              ><span class="text-dark-75">
                                {{
                                  optionalObject(item.checker_pic, "code")
                                }}</span
                              >
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Scope : </span
                              ><span class="text-dark-75">{{
                                optionalObject(item.scope, "name")
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Designer Hour : </span
                              ><span class="text-dark-75">
                                {{ item.designer_hour }}</span
                              >
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Drafter Hour : </span
                              ><span class="text-dark-75">{{
                                item.drafter_hour
                              }}</span>
                            </div>

                            <div class="col-md-3">
                              <span
                                class="text-dark-75 font-weight-bolder font-size-lg"
                                >Checker Hour : </span
                              ><span class="text-dark-75">{{
                                item.checker_hour
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="historyModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import {
  arrayMoreThanOne,
  formattedOptions,
} from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import { GET_OUTSTANDING } from "../../../core/services/store/outstanding.module";
import { GET_OUTSTANDING_CATEGORY } from "../../../core/services/store/outstanding-category.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import {
  GET_AS_BUILT_DRAWING,
  UPDATE_AS_BUILT_DRAWING,
} from "../../../core/services/store/asbuilt-drawing.module";
import OnEditRow from "../../content/widgets/advance-table/row/OnEditRow";
import { GET_PIC } from "../../../core/services/store/pic.module";
import { GET_STATUS_OPTION } from "../../../core/services/store/status.module";
import { excelToArray } from "@/core/helper/excel.helper";
import { exportExcelColumn } from "@/view/pages/reporting/asbuiltDrawing";
import ApiService from "@/core/services/api.service";
import { showToast } from "../../../core/helper/toast.helper";
import { optionalData } from "@/core/helper/object-validation.helper";
import { GET_DIVISION } from "@/core/services/store/division.module";
import {
  SHOW_PROJECT_ADMIN,
  SHOW_PROJECT_DESIGN,
} from "@/core/services/store/dl.design.module.js";

export default {
  name: "MonitoringAsbuiltDrawing",
  components: { OnEditRow, CompleteTable },
  data() {
    return {
      expandedIndexDetail: null,
      historyLoading: false,
      histories: [],
      historyModal: false,
      servers: [],
      server: null,
      fileName: "",
      searchData: "",
      Datas: [],
      uploadLoading: false,
      showSelectServer: false,
      selectedFullPath: "",
      selectedServerName: null,
      exportExcelColumn: exportExcelColumn,
      currentEditId: null,
      currentEditForm: {
        external_numbering: null,
        asbuilt_file: null,
        asbuilt_drawing_numbering: null,
        status: null,
        revision_version: null,
        version: null,
        planning_date: null,
        drafter_pic: null,
        outstanding: null,
        storage: null,
        transmittal_date: null,
        date_receive: null,
        date_completed: null,
        receiver: null,
      },
      currentItem: null,
      filter: {
        id_project: null,
        nickname: null,
        station: null,
        scope: null,
        job_desk: "design",
        code: null,
      },
      selectNickname: {
        code: null,
        text: null,
      },
      selectedNickname: {
        ownerName: null,
        projectName: null,
        status: null,
        contractNumber: null,
        code: null,
      },
    };
  },
  computed: {
    ...mapState({
      outstandings: (state) => state.outstanding.outstanding?.data,
      outstandingCategory: (state) =>
        state.outstandingCategory.outstandingCategories?.data,
      projects: (state) => state.projectDetails.projectDetails?.data,
      asBuiltDrawing: (state) => state.asBuiltDrawing.asBuiltDrawing,
      loading: (state) => state.asBuiltDrawing.loading,
      pics: (state) => state.pic.pics?.data,
      statuses: (state) => state.status.status?.data,
      divisions: (state) => state.division.divisions?.data ?? [],
    }),

    dataWithFilter() {
      return this.Datas.filter((items) => {
        return (
          (items.numbering &&
            items.numbering
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.title &&
            items.title.toLowerCase().indexOf(this.searchData.toLowerCase()) >
              -1) ||
          (items.station &&
            items.station.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.type &&
            items.type.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.scope &&
            items.scope.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.last_status &&
            items.last_status.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.revision_version &&
            items.revision_version
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.date_dist_logistic &&
            items.date_dist_logistic
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.logistic_receiver &&
            items.logistic_receiver.name
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.external_numbering &&
            items.external_numbering
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1) ||
          (items.asbuilt_drawing_numbering &&
            items.asbuilt_drawing_numbering
              .toLowerCase()
              .indexOf(this.searchData.toLowerCase()) > -1)
        );
      });
    },

    currentAsBuiltDrawings() {
      return this.asBuiltDrawing?.data ?? [];
    },

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Nickname" }, ...projects];
    },

    formattedPicOptions() {
      let pics = [];

      if (arrayMoreThanOne(this.pics)) {
        pics = this.pics.map((pic) => {
          return {
            value: pic.code,
            text: pic.name,
          };
        });
      }

      return [{ value: null, text: "Choose PIC" }, ...pics];
    },

    formattedOutstandingOptions() {
      let outstanding = [];

      if (arrayMoreThanOne(this.outstandings)) {
        outstanding = this.outstandings.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Outstanding" }, ...outstanding];
    },

    formattedReceiverOptions() {
      let receiver = [];

      if (arrayMoreThanOne(this.outstandingCategory)) {
        receiver = this.outstandingCategory.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Receiver" }, ...receiver];
    },

    formattedStatusOptions() {
      let status = [];

      if (arrayMoreThanOne(this.statuses)) {
        status = this.statuses.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Status" }, ...status];
    },

    currentNickname() {
      return this.selectNickname.code;
    },
    formattedDivisionOptions() {
      return formattedOptions(this.divisions);
    },
    excelData() {
      return this.currentAsBuiltDrawings?.map((asBuiltDrawing) => {
        return {
          id: asBuiltDrawing?.id,
          numbering: asBuiltDrawing.numbering,
          external_numbering: asBuiltDrawing.external_numbering,
          asbuilt_drawing_numbering: asBuiltDrawing.asbuilt_drawing_numbering,
          title: asBuiltDrawing.title,
          station: asBuiltDrawing.station?.code,
          type: asBuiltDrawing.type?.code,
          scope: asBuiltDrawing.scope?.code,
          last_status: asBuiltDrawing.last_status?.code,
          revision_version: asBuiltDrawing.revision_version,
          version: asBuiltDrawing.version,
          planning_date: asBuiltDrawing.planning_date,
          drafter_pic: asBuiltDrawing.drafter_pic?.code,
          outstanding: asBuiltDrawing.outstanding?.code,
          date_completed: asBuiltDrawing.date_completed,
          asbuilt_file: asBuiltDrawing.asbuilt_file,
          transmittal_number: asBuiltDrawing.transmittal_number,
          transmittal_date: asBuiltDrawing.transmittal_date,
          date_receive: asBuiltDrawing.date_receive,
          receiver: asBuiltDrawing.receiver?.code,
        };
      });
    },
    projectList() {
      let projectAdmin = [
        ...[{ code: null, nickname: "Choose Nickname" }],
        ...this.$store.getters.getterProjectAdmin,
      ];
      let projectDesign = this.$store.getters.getterProjectDesign;
      return this.merge(projectAdmin, projectDesign, "code");
    },
  },
  watch: {
    currentNickname(value) {
      if (!value) {
        Object.assign(this.selectedNickname, {
          ownerName: null,
          projectName: null,
          status: null,
          contractNumber: null,
          code: null,
        });

        return;
      }

      const project = this.projectList.find(
        (x) => x.code === this.selectNickname.code
      );
      this.fileName =
        project.code +
        " - " +
        project.nickname +
        " - monitoring as built drawing";
      Object.assign(this.selectedNickname, {
        ownerName: project?.owner,
        projectName: project?.name,
        status: project?.status,
        contractNumber: project?.contract_no,
        ...project,
      });
    },
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },

    uploadFile(file) {
      ApiService.setHeader();

      const formData = new FormData();

      formData.append("id", this.currentEditId);

      formData.append("file", file);

      formData.append("ftp_id", this.server);

      formData.append("path", this.selectedFullPath);

      this.uploadLoading = true;

      ApiService.post("project/monitoring/asbuilt/drawing/upload/path", formData)
        .then((response) => {
          showToast("Success", "Upload success", "success");

          this.server = null;
          console.log(response);
          this.currentEditForm.asbuilt_file =
            response?.data?.data?.asbuilt_file;

          this.currentEditForm.storage = this.server;

          this.showSelectServer = false;

          this.uploadLoading = false;
        })
        .catch((error) => {
          this.uploadLoading = false;

          if(error.response.status == 409) {
            showToast("Error", error.response.data.message, "danger");
            console.log('server sesudah upload ', this.currentEditForm.file);
          }else{
            showToast("Error", "Upload failed", "danger");
          }
        });
    },

    selectServer(event) {
      this.server = event[0]?.id;

      this.selectedServerName = event[0]?.name;
      this.selectedFullPath = event[0]?.full_path;
      console.log("selected full path " + this.selectedFullPath);
      console.log("event ", event[0]?.full_path);

      //isi current selected directory child
      //ditambahkan terlebih dahulu check kalau panjang children = 0 maka ditambahkan, jika sudah terisi, jangan ditambahkan
      if (this.selectedNickname.code !== null) {
        let url = "ftp/folder/" + this.selectNickname.code + "/remote";
        ApiService.setHeader();
        ApiService.query(url,{
          params: {
            path: this.selectedFullPath
          }
        })
          .then((response) => {
            event[0].children =this.subMenu(response?.data?.data ?? []);
            event[0].expanded = true;
          })
          .catch(() => {
            showToast("Error", "Failed to fetch servers", "error");
          }); 
      }

    },

    fetchServers() {
      // ApiService.setHeader();

      // ApiService.query("ftp/folder")
      //   .then((response) => {
      //     this.servers = this.subMenu(response?.data?.data ?? []);
      //   })
      //   .catch(() => {
      //     showToast("Error", "Failed to fetch servers", "error");
      //   });

        if (this.selectNickname.code !== null) {
        let url = "ftp/folder/" + this.selectNickname.code + "/remote";
        ApiService.setHeader();
        ApiService.query(url)
          .then((response) => {
            this.servers = this.subMenu(response?.data?.data ?? []);
          })
          .catch(() => {
            showToast("Error", "Failed to fetch servers", "error");
          }); 
      }
    },

    subMenu(listSubMenu) {
      let result = [];

      if (arrayMoreThanOne(listSubMenu)) {
        result = listSubMenu.map((menu) => {
          return {
            id: menu.name,
            name: menu.name,
            children: this.subMenu(menu.children),
            expanded: false,
            full_path: menu.full_path,
          };
        });
      }

      return result;
    },

    handleUploadExcel(event) {
      const file = event.target.files[0];

      excelToArray(file, this.postExcelData);
    },

    postExcelData(data) {
      var project = this.formattedProjectOptions.find(
        (x) => x.value == this.filter.nickname
      );
      this.$store
        .dispatch(UPDATE_AS_BUILT_DRAWING, {
          project,
          dld: data?.map((item) => {
            const status = this.formattedStatusOptions.find(
              (status) => status.value === item[8]
            );

            const outstanding = this.formattedOutstandingOptions.find(
              (outstanding) => outstanding.value === item[13]
            );

            const receiver = this.formattedReceiverOptions.find(
              (receiver) => receiver.value === item[19]
            );
            const pic = this.formattedPicOptions.find(
              (pic) => pic.value === item[12]
            );

            return {
              id: item[0] ?? null,
              external_numbering: item[2] ?? null,
              asbuilt_drawing_numbering: item[3] ?? null,
              status: {
                code: status?.value ?? null,
                name: status?.text ?? null,
              },
              revision_version: item[9] ?? null,
              version: item[10] ?? null,
              drafter_pic: {
                code: pic?.value ?? null,
                name: pic?.text ?? null,
              },
              receiver: {
                code: receiver?.value ?? null,
                name: receiver?.text ?? null,
              },
              outstanding: {
                code: outstanding?.value ?? null,
                name: outstanding?.text ?? null,
              },
              asbuilt_file: item[15] ?? null,
              transmittal_date: item[17] ?? null,
              date_received: item[18] ?? null,
              planning_date: item[11] ?? null,
            };
          }),
        })
        .then(() => {
          this.loadData();
        });
    },

    uploadExcel() {
      this.$refs.uploadExcel.click();
    },

    loadData() {
      if (!this.selectNickname.code) {
        this.$bvToast.toast("Please Choose Project First.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        this.getAsBuiltDrawings();
      }
    },

    editData(item) {
      this.currentEditId = item.id;

      this.currentItem = item;

      this.currentEditForm = {
        external_numbering: item.external_numbering,
        asbuilt_drawing_numbering: item.asbuilt_drawing_numbering,
        status: item.last_status?.code,
        revision_version: item.revision_version,
        version: item.version,
        planning_date: item.planning_date,
        drafter_pic: item.drafter_pic?.code,
        outstanding: item.outstanding?.code,
        storage: item.asbuilt_file,
        transmittal_date: item.transmittal_date,
        date_receive: item.date_receive,
        date_completed: item.date_completed,
        asbuilt_file: item.asbuilt_file,
        receiver: item.receiver?.code,
      };
    },

    cancelEdit() {
      this.currentEditId = null;

      this.currentEditForm = {
        external_numbering: null,
        asbuilt_drawing_numbering: null,
        status: null,
        revision_version: null,
        version: null,
        planning_date: null,
        drafter_pic: null,
        outstanding: null,
        storage: null,
        transmittal_date: null,
        date_receive: null,
        asbuilt_file: null,
        date_completed: null,
        receiver: null,
      };

      this.currentItem = null;
    },

    save() {
      const status = this.formattedStatusOptions.find(
        (status) => status.value === this.currentEditForm.status
      );

      const outstanding = this.formattedOutstandingOptions.find(
        (outstanding) => outstanding.value === this.currentEditForm.outstanding
      );

      const receiver = this.formattedReceiverOptions.find(
        (receiver) => receiver.value === this.currentEditForm.receiver
      );

      const pic = this.formattedPicOptions.find(
        (pic) => pic.value === this.currentEditForm.drafter_pic
      );

      const cleanForm = {
        id: this.currentItem.id,
        external_numbering: this.currentEditForm.external_numbering,
        asbuilt_drawing_numbering: this.currentEditForm
          .asbuilt_drawing_numbering,
        last_status: {
          code: status?.value,
          name: status?.text,
        },
        status: {
          code: status?.value,
          name: status?.text,
        },
        revision_version: this.currentEditForm.revision_version,
        version: this.currentEditForm.version,
        drafter_pic: {
          code: pic?.value,
          name: pic?.text,
        },
        receiver: {
          code: receiver?.value,
          name: receiver?.text,
        },
        outstanding: {
          code: outstanding?.value,
          name: outstanding?.text,
        },
        asbuilt_file: this.currentEditForm.asbuilt_file,
        transmittal_date: this.currentEditForm.transmittal_date,
        date_received: this.currentEditForm.date_receive,
        planning_date: this.currentEditForm.planning_date,
        date_completed: this.currentEditForm.date_completed,
        // receiver: receiver,
      };
      var project = this.formattedProjectOptions.find(
        (x) => x.value == this.filter.nickname
      );
      this.$store
        .dispatch(UPDATE_AS_BUILT_DRAWING, { project, dld: [cleanForm] })
        .then(() => {
          this.cancelEdit();

          this.getAsBuiltDrawings();
        });
    },

    toEditPage() {
      //
    },
    downloadFile(url, fileName) {
      const n = fileName.lastIndexOf("/");
      const newFileName = fileName.substring(n + 1);
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: url,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );

          const fileLink = document.createElement("a");

          fileLink.href = fileURL;

          fileLink.setAttribute("download", newFileName);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          showToast("Error", "Download Failed, Please Reupload File", "danger");
        });
    },
    getAsBuiltDrawings() {
      this.$store
        .dispatch(GET_AS_BUILT_DRAWING, {
          params: {
            ...this.filter,
            project_code: this.currentNickname,
          },
        })
        .then((x) => {
          this.Datas = x.data.map((y) => {
            if (y.last_status == null || y.last_status == undefined)
              y.last_status = {
                code: "-",
                name: "-",
              };

            if (y.logistic_receiver == null || y.logistic_receiver == undefined)
              y.logistic_receiver = {
                code: "-",
                name: "-",
              };

            if (y.revision_version == null) {
              y.revision_version = "-";
            }

            if (y.date_dist_logistic == null || y.date_dist_logistic == "-") {
              y.date_dist_logistic = "-";
            }

            if (y.external_numbering == null || y.external_numbering == "-") {
              y.external_numbering = "-";
            }

            if (
              y.asbuilt_drawing_numbering == null ||
              y.asbuilt_drawing_numbering == "-"
            ) {
              y.asbuilt_drawing_numbering = "-";
            }

            return y;
          });
          console.log("DATA", this.Datas);
        });
    },

    getShowName(item) {
      const index = this.options.pics.findIndex(
        (pic) => pic.value === item.pic_drafter.id
      );

      return this.options.pics[index]?.text;
    },

    getShowOutStanding(item) {
      const index = this.options.outstandings.findIndex(
        (pic) => pic.value === item.outstanding.id
      );

      return this.options.outstandings[index]?.text;
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, { jobdesk: "design" });
    },

    getOutstandings() {
      if (!arrayMoreThanOne(this.outstandings)) {
        this.$store.dispatch(GET_OUTSTANDING, this.filter);
      }
    },

    getOutstandingCategory() {
      if (!arrayMoreThanOne(this.outstandingCategory)) {
        this.$store.dispatch(GET_OUTSTANDING_CATEGORY, this.filter);
      }
    },

    getStatuses() {
      if (!arrayMoreThanOne(this.statuses)) {
        this.$store.dispatch(GET_STATUS_OPTION, {});
      }
    },

    getPics() {
      if (!arrayMoreThanOne(this.pics)) {
        this.$store.dispatch(GET_PIC, {});
      }
    },

    isEdit(id) {
      return id === this.currentEditId;
    },

    setExpandedIndexDetail(index) {
      if (this.expandedIndexDetail === index) {
        this.expandedIndexDetail = null;
      } else {
        this.expandedIndexDetail = index;
      }
    },

    openHistoryModal(item) {
      this.historyLoading = true;

      ApiService.setHeader();

      ApiService.query(`project/dld/${item.id}/history/ABD/menu`)
        .then((response) => {
          this.histories = response.data.data;

          this.historyLoading = false;

          this.historyModal = true;
        })
        .catch(() => {
          this.historyModal = false;

          this.historyLoading = false;

          showToast("Info", "Nothing Change", "info");
        });
    },

    optionalObject(object, key) {
      return optionalData(object, key);
    },
    selectProject(){
      console.log(this.selectNickname);
      this.fetchServers();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Monitor Asbuilt Drawing" },
    ]);

    this.getOutstandings();

    this.getOutstandingCategory();

    //this.getProjects();

    this.getPics();

    this.getStatuses();

    // this.fetchServers();

    this.$store.dispatch(GET_DIVISION);
  },
  async created() {
    this.$store.dispatch(SHOW_PROJECT_ADMIN);
    this.$store.dispatch(SHOW_PROJECT_DESIGN);
  },
};
</script>

<style></style>
