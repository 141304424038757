export const exportExcelColumn = [
  {
    label: "Id (Tidak bisa dirubah)",
    field: "id",
  },
  {
    label: "Numbering",
    field: "numbering",
  },
  {
    label: "External Numbering (Bisa dirubah)",
    field: "external_numbering",
  },
  {
    label: "Asbuilt Drawing Numbering (Bisa dirubah)",
    field: "asbuilt_drawing_numbering",
  },
  {
    label: "Title",
    field: "title",
  },
  {
    label: "Station",
    field: "station",
  },
  {
    label: "Type",
    field: "type",
  },
  {
    label: "Scope",
    field: "scope",
  },
  {
    label: "Last Status (Bisa dirubah)",
    field: "last_status",
  },
  {
    label: "Revision Version (Bisa dirubah)",
    field: "revision_version",
  },
  {
    label: "Version (Bisa dirubah)",
    field: "version",
  },
  {
    label: "Planning Date (Bisa dirubah)",
    field: "planning_date",
  },
  {
    label: "Drafter PIC (Bisa dirubah)",
    field: "drafter_pic",
  },
  {
    label: "Outstanding (Bisa dirubah)",
    field: "outstanding",
  },
  {
    label: "Date Completed",
    field: "date_completed",
  },
  {
    label: "Asbuilt File",
    field: "asbuilt_file",
  },
  {
    label: "Transmittal Number",
    field: "transmittal_number",
  },
  {
    label: "Transmittal Date (Bisa dirubah)",
    field: "transmittal_date",
  },
  {
    label: "Date Receive (Bisa dirubah)",
    field: "date_receive",
  },
  {
    label: "Receiver (Bisa dirubah)",
    field: "receiver",
  },
];
